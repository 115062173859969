.Wrapper {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  background-color: white;
  z-index: 1;
  margin-left: 100%;
}
.WrapperBox {
  width: 80%;
  min-height: 93vh;
  margin-left: 10%;
  margin-top: 6%;
  display: flex;
  flex-direction: column;
}
.Back {
  width: 100%;
  min-height: 70px;
}
.Line {
  width: 100%;
  min-height: 10px;
}
.Line > :nth-child(1) {
  width: 40px;
  min-height: 6px;
  background-color: #ed013e;
}
.Title {
  font-family: Aboreto;
  font-size: 1rem;
  width: 100%;
  min-height: 25px;
}
.Bodi {
  width: 100%;
  max-height: calc(93vh - 155px);
  min-height: calc(93vh - 155px);
  overflow-x: scroll;
}
.Footer {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Button {
  background-color: #ed013e;
  width: 100%;
  height: 45px;
  font-family: Aboreto;
  font-size: 0.8rem;
  font-weight: bold;
}
.DIE {
  font-size: 0.8rem;
  text-align: justify;
  flex-direction: column;
}
.DIE span {
  margin-top: 15px;
  width: 90%;
  opacity: 0.7;
}
.BodiTitle {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
}
.Bitte {
  font-size: 0.8rem;
  opacity: 0.7;
}
.Dein {
  font-size: 1.5rem;
  opacity: 0.7;
  font-weight: bold;
  margin-top: -5px;
}
