.Wrapper {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: row;
}
.Coffe1 {
  display: flex;
  flex-direction: row;
}
.Coffe2 {
  background-color: #ffc300;
}
.COFFEE2 {
  width: 150px;
  height: 127px;
  margin-top: -65px;
}
.Portfolio {
  flex-direction: row;
  margin-top: 50px;
}
.Portfolio > :nth-child(1) {
  width: 150px;
  min-height: 35px;
  font-family: Aboreto;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  margin-left: -143px;
}
.Portfolio > :nth-child(2) {
  width: 170px;
  min-height: 35px;
  font-family: Aboreto;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffc300;
  background-color: white;
}
.Info {
  width: 70%;
  height: 70vh;
  margin-top: 15vh;
  display: flex;
  flex-direction: row;
}
.Info > :nth-child(1) {
  width: 30px;
  min-height: 100%;
}
.Info > :nth-child(1) > :nth-child(1) {
  width: 8px;
  min-height: 100%;
  background-color: #ffc300;
}
.Info > :nth-child(2) {
  width: calc(100% - 30px);
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.InfoIcon {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.InfoLine {
  width: 60px;
  height: 8px;
  background-color: #ed013e;
  margin-top: 15px;
  margin-left: 15px;
}
.InfoDU {
  font-size: 0.8rem;
  color: rgba(100, 100, 100);
  margin-top: 5px;
  margin-left: 15px;
}
.InfoLB {
  font-family: Aboreto;
  font-size: 2rem;
  font-weight: bold;
  margin-left: 11px;
  margin-top: -3px;
  color: rgba(30, 30, 30);
}
.InfoDIE {
  font-size: 0.9rem;
  color: rgba(70, 70, 70);
  margin-left: 15px;
  width: 100%;
  text-align: justify;
  margin-top: 15px;
  overflow-x: scroll;
}
.ArrowForwardIcon {
  width: 15px;
  height: auto;
}

/* ------------------------------- Animations ---------------------- */
.AUL {
  display: none;
  position: relative;
  font-size: 0.75rem;
  color: rgba(100, 100, 100);
  margin-left: 15px;
  cursor: pointer;
  width: 80px;
}
.AUL:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  top: 22px;
  background-color: #ed013e;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}
.AUL:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* ################################################################################## */
@media (min-width: 0px) and (max-width: 560px) {
  .Wrapper {
    flex-direction: column;
  }
  .COFFEE1 {
    display: none;
  }
  .Info {
    width: 90%;
    max-height: 76%;
    margin-top: 12%;
    margin-left: 5%;
    flex-direction: column;
  }
  .Info > :nth-child(1) {
    display: none;
  }
  .Info > :nth-child(2) {
    width: 100%;
    min-height: 100%;
  }
  .AUL {
    display: inline-block;
  }
  .InfoDIE {
    display: none;
  }
}
@media (min-width: 561px) and (max-width: 768px) {
  .COFFEE2 {
    display: none;
  }
  .COFFEE1 {
    width: 150px;
    height: 176px;
    margin-left: -75px;
  }
  .Portfolio {
    flex-direction: column;
    margin-left: 10px;
  }
  .Portfolio > :nth-child(1) {
    margin-left: 0px;
  }
  .Portfolio > :nth-child(2) {
    width: 160px;
    align-items: flex-start;
  }
  /* .InfoIcon {
    display: none;
  } */
}
@media (min-width: 769px) {
  .COFFEE2 {
    display: none;
  }
  .COFFEE1 {
    width: 200px;
    height: 235px;
    margin-left: -100px;
  }
  /* .InfoIcon {
    display: none;
  } */
}
@media (min-width: 991px) {
  .COFFEE1 {
    width: 230px;
    height: 270px;
    margin-left: -115px;
  }
}
