body {
  margin: 0;
  cursor: default;
  font-family: P10;
  background-color: white;
}

@font-face {
  font-family: "DINPro-Black";
  src: url("./fonts/DINPro-Black.otf") format("truetype");
}
@font-face {
  font-family: "DINPro-Bold";
  src: url("./fonts/DINPro-Bold.otf") format("truetype");
}
@font-face {
  font-family: "DINPro-Light";
  src: url("./fonts/DINPro-Light.otf") format("truetype");
}
@font-face {
  font-family: "DINPro-Medium";
  src: url("./fonts/DINPro-Medium.otf") format("truetype");
}
@font-face {
  font-family: "DINPro-Regular";
  src: url("./fonts/DINPro-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Helvetica-CE-Bold";
  src: url("./fonts/Helvetica CE Bold.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeueLTPro-Bd";
  src: url("./fonts/HelveticaNeueLTPro-Bd.otf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeueLTPro-Cn";
  src: url("./fonts/HelveticaNeueLTPro-Cn.otf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeueLTPro-Lt";
  src: url("./fonts/HelveticaNeueLTPro-Lt.otf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeueLTPro-Roman";
  src: url("./fonts/HelveticaNeueLTPro-Roman.otf") format("truetype");
}
@font-face {
  font-family: "FA-EN01";
  src: url("./fonts/Ravi FA-EN 01 Thin (2).ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN02";
  src: url("./fonts/Ravi FA-EN 02 Light.ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN03";
  src: url("./fonts/Ravi FA-EN 03 Regular.ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN04";
  src: url("./fonts/Ravi FA-EN 04 Medium.ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN05";
  src: url("./fonts/Ravi FA-EN 05 SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN06";
  src: url("./fonts/Ravi FA-EN 06 Bold.ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN07";
  src: url("./fonts/Ravi FA-EN 07 ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "FA-EN08";
  src: url("./fonts/Ravi FA-EN 08 Black.ttf") format("truetype");
}

@font-face {
  font-family: "P01";
  src: url("./fonts/Poppins/P01.ttf") format("truetype");
}
@font-face {
  font-family: "P02";
  src: url("./fonts/Poppins/P02.ttf") format("truetype");
}
@font-face {
  font-family: "P03";
  src: url("./fonts/Poppins/P03.ttf") format("truetype");
}
@font-face {
  font-family: "P04";
  src: url("./fonts/Poppins/P04.ttf") format("truetype");
}
@font-face {
  font-family: "P05";
  src: url("./fonts/Poppins/P05.ttf") format("truetype");
}
@font-face {
  font-family: "P06";
  src: url("./fonts/Poppins/P06.ttf") format("truetype");
}
@font-face {
  font-family: "P07";
  src: url("./fonts/Poppins/P07.ttf") format("truetype");
}
@font-face {
  font-family: "P08";
  src: url("./fonts/Poppins/P08.ttf") format("truetype");
}
@font-face {
  font-family: "P09";
  src: url("./fonts/Poppins/P09.ttf") format("truetype");
}
@font-face {
  font-family: "P10";
  src: url("./fonts/Poppins/P10.ttf") format("truetype");
}
@font-face {
  font-family: "P11";
  src: url("./fonts/Poppins/P11.ttf") format("truetype");
}
@font-face {
  font-family: "P12";
  src: url("./fonts/Poppins/P12.ttf") format("truetype");
}
@font-face {
  font-family: "P13";
  src: url("./fonts/Poppins/P13.ttf") format("truetype");
}
@font-face {
  font-family: "P14";
  src: url("./fonts/Poppins/P14.ttf") format("truetype");
}
@font-face {
  font-family: "P15";
  src: url("./fonts/Poppins/P15.ttf") format("truetype");
}
@font-face {
  font-family: "P16";
  src: url("./fonts/Poppins/P16.ttf") format("truetype");
}
@font-face {
  font-family: "P17";
  src: url("./fonts/Poppins/P17.ttf") format("truetype");
}
@font-face {
  font-family: "P18";
  src: url("./fonts/Poppins/P18.ttf") format("truetype");
}
@font-face {
  font-family: "Aboreto";
  src: url("./fonts/Aboreto-Regular.ttf") format("truetype");
}
