@keyframes RTL {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
}
@keyframes LTR {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 100%;
  }
}
